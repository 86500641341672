export { svgMapStatPerCountryPush };

const svgMapStatPerCountryPush = {
    data: {
        impressions: {
            name: 'Impressions',
        },
        clicks: {
            name: 'Clicks',
        },
        avgCPC: {
            name: 'CPC, $',
        },
    },
    applyData: 'clicks',
    values: {
    "AD": {
        "countryISO": "AD",
        "country": "Andorra",
        "impressions": 25766,
        "clicks": 8,
        "avgCPC": 0.0028
    },
    "AE": {
        "countryISO": "AE",
        "country": "United Arab Emirates",
        "impressions": 1705826,
        "clicks": 8622,
        "avgCPC": 0.0098
    },
    "AG": {
        "countryISO": "AG",
        "country": "Antigua and Barbuda",
        "impressions": 29409,
        "clicks": 41,
        "avgCPC": 0.0066
    },
    "AI": {
        "countryISO": "AI",
        "country": "Anguilla",
        "impressions": 2597,
        "clicks": 2,
        "avgCPC": 0.0069
    },
    "AL": {
        "countryISO": "AL",
        "country": "Albania",
        "impressions": 837731,
        "clicks": 539,
        "avgCPC": 0.0028
    },
    "AM": {
        "countryISO": "AM",
        "country": "Armenia",
        "impressions": 1542818,
        "clicks": 633,
        "avgCPC": 0.0038
    },
    "AO": {
        "countryISO": "AO",
        "country": "Angola",
        "impressions": 402472,
        "clicks": 1312,
        "avgCPC": 0.0186
    },
    "AR": {
        "countryISO": "AR",
        "country": "Argentina",
        "impressions": 7432343,
        "clicks": 21050,
        "avgCPC": 0.007
    },
    "AT": {
        "countryISO": "AT",
        "country": "Austria",
        "impressions": 1346925,
        "clicks": 1349,
        "avgCPC": 0.0649
    },
    "AU": {
        "countryISO": "AU",
        "country": "Australia",
        "impressions": 1330795,
        "clicks": 2832,
        "avgCPC": 0.1175
    },
    "AW": {
        "countryISO": "AW",
        "country": "Aruba",
        "impressions": 39496,
        "clicks": 18,
        "avgCPC": 0.0045
    },
    "AX": {
        "countryISO": "AX",
        "country": "Aland Islands",
        "impressions": 5542,
        "clicks": 8,
        "avgCPC": 0.0046
    },
    "AZ": {
        "countryISO": "AZ",
        "country": "Azerbaijan",
        "impressions": 3232249,
        "clicks": 1052,
        "avgCPC": 0.0071
    },
    "BB": {
        "countryISO": "BB",
        "country": "Barbados",
        "impressions": 71658,
        "clicks": 59,
        "avgCPC": 0.0057
    },
    "BE": {
        "countryISO": "BE",
        "country": "Belgium",
        "impressions": 2895378,
        "clicks": 1738,
        "avgCPC": 0.0455
    },
    "BF": {
        "countryISO": "BF",
        "country": "Burkina Faso",
        "impressions": 153706,
        "clicks": 98,
        "avgCPC": 0.0058
    },
    "BG": {
        "countryISO": "BG",
        "country": "Bulgaria",
        "impressions": 4257698,
        "clicks": 2627,
        "avgCPC": 0.0175
    },
    "BH": {
        "countryISO": "BH",
        "country": "Bahrain",
        "impressions": 589108,
        "clicks": 525,
        "avgCPC": 0.0062
    },
    "BI": {
        "countryISO": "BI",
        "country": "Burundi",
        "impressions": 44576,
        "clicks": 94,
        "avgCPC": 0.0026
    },
    "BJ": {
        "countryISO": "BJ",
        "country": "Benin",
        "impressions": 149926,
        "clicks": 264,
        "avgCPC": 0.0057
    },
    "BL": {
        "countryISO": "BL",
        "country": "Saint Barthelemy",
        "impressions": 2070,
        "clicks": 0,
        "avgCPC": 0.0022
    },
    "BM": {
        "countryISO": "BM",
        "country": "Bermuda",
        "impressions": 5822,
        "clicks": 3,
        "avgCPC": 0.0025
    },
    "BN": {
        "countryISO": "BN",
        "country": "Brunei",
        "impressions": 432707,
        "clicks": 110,
        "avgCPC": 0.0018
    },
    "BO": {
        "countryISO": "BO",
        "country": "Bolivia",
        "impressions": 523131,
        "clicks": 2108,
        "avgCPC": 0.0027
    },
    "BQ": {
        "countryISO": "BQ",
        "country": "Bonaire, Saint Eustatius and Saba ",
        "impressions": 8623,
        "clicks": 10,
        "avgCPC": 0.0024
    },
    "BR": {
        "countryISO": "BR",
        "country": "Brazil",
        "impressions": 8298699,
        "clicks": 29591,
        "avgCPC": 0.0118
    },
    "BS": {
        "countryISO": "BS",
        "country": "Bahamas",
        "impressions": 71655,
        "clicks": 74,
        "avgCPC": 0.008
    },
    "BT": {
        "countryISO": "BT",
        "country": "Bhutan",
        "impressions": 384606,
        "clicks": 127,
        "avgCPC": 0.0016
    },
    "BW": {
        "countryISO": "BW",
        "country": "Botswana",
        "impressions": 65905,
        "clicks": 197,
        "avgCPC": 0.0053
    },
    "BZ": {
        "countryISO": "BZ",
        "country": "Belize",
        "impressions": 70699,
        "clicks": 64,
        "avgCPC": 0.0029
    },
    "CA": {
        "countryISO": "CA",
        "country": "Canada",
        "impressions": 2997742,
        "clicks": 6946,
        "avgCPC": 0.0975
    },
    "CF": {
        "countryISO": "CF",
        "country": "Central African Republic",
        "impressions": 10608,
        "clicks": 15,
        "avgCPC": 0.0024
    },
    "CH": {
        "countryISO": "CH",
        "country": "Switzerland",
        "impressions": 1216247,
        "clicks": 940,
        "avgCPC": 0.0865
    },
    "CI": {
        "countryISO": "CI",
        "country": "Ivory Coast",
        "impressions": 469528,
        "clicks": 646,
        "avgCPC": 0.0099
    },
    "CK": {
        "countryISO": "CK",
        "country": "Cook Islands",
        "impressions": 2100,
        "clicks": 0,
        "avgCPC": 0.0031
    },
    "CL": {
        "countryISO": "CL",
        "country": "Chile",
        "impressions": 2582513,
        "clicks": 4063,
        "avgCPC": 0.0186
    },
    "CM": {
        "countryISO": "CM",
        "country": "Cameroon",
        "impressions": 944246,
        "clicks": 1594,
        "avgCPC": 0.0098
    },
    "CN": {
        "countryISO": "CN",
        "country": "China",
        "impressions": 579358,
        "clicks": 276,
        "avgCPC": 0.0023
    },
    "CO": {
        "countryISO": "CO",
        "country": "Colombia",
        "impressions": 5126285,
        "clicks": 10048,
        "avgCPC": 0.0069
    },
    "CR": {
        "countryISO": "CR",
        "country": "Costa Rica",
        "impressions": 645835,
        "clicks": 605,
        "avgCPC": 0.0051
    },
    "CV": {
        "countryISO": "CV",
        "country": "Cabo Verde",
        "impressions": 79652,
        "clicks": 100,
        "avgCPC": 0.0023
    },
    "CW": {
        "countryISO": "CW",
        "country": "Curacao",
        "impressions": 56195,
        "clicks": 27,
        "avgCPC": 0.0022
    },
    "CX": {
        "countryISO": "CX",
        "country": "Christmas Island",
        "impressions": 1420,
        "clicks": 12,
        "avgCPC": 0.001
    },
    "CY": {
        "countryISO": "CY",
        "country": "Cyprus",
        "impressions": 309025,
        "clicks": 266,
        "avgCPC": 0.021
    },
    "CZ": {
        "countryISO": "CZ",
        "country": "Czechia",
        "impressions": 1764560,
        "clicks": 2310,
        "avgCPC": 0.0256
    },
    "DE": {
        "countryISO": "DE",
        "country": "Germany",
        "impressions": 14685382,
        "clicks": 11165,
        "avgCPC": 0.0585
    },
    "DJ": {
        "countryISO": "DJ",
        "country": "Djibouti",
        "impressions": 43762,
        "clicks": 62,
        "avgCPC": 0.0021
    },
    "DK": {
        "countryISO": "DK",
        "country": "Denmark",
        "impressions": 909236,
        "clicks": 1015,
        "avgCPC": 0.0388
    },
    "DM": {
        "countryISO": "DM",
        "country": "Dominica",
        "impressions": 12948,
        "clicks": 12,
        "avgCPC": 0.0046
    },
    "DO": {
        "countryISO": "DO",
        "country": "Dominican Republic",
        "impressions": 5314284,
        "clicks": 5027,
        "avgCPC": 0.0023
    },
    "EC": {
        "countryISO": "EC",
        "country": "Ecuador",
        "impressions": 2533930,
        "clicks": 4428,
        "avgCPC": 0.0056
    },
    "EE": {
        "countryISO": "EE",
        "country": "Estonia",
        "impressions": 686861,
        "clicks": 312,
        "avgCPC": 0.0375
    },
    "EG": {
        "countryISO": "EG",
        "country": "Egypt",
        "impressions": 2380987,
        "clicks": 8997,
        "avgCPC": 0.0024
    },
    "EH": {
        "countryISO": "EH",
        "country": "Western Sahara",
        "impressions": 358,
        "clicks": 1,
        "avgCPC": 0.003
    },
    "ES": {
        "countryISO": "ES",
        "country": "Spain",
        "impressions": 8026546,
        "clicks": 7159,
        "avgCPC": 0.0386
    },
    "ET": {
        "countryISO": "ET",
        "country": "Ethiopia",
        "impressions": 549126,
        "clicks": 1512,
        "avgCPC": 0.002
    },
    "FI": {
        "countryISO": "FI",
        "country": "Finland",
        "impressions": 768583,
        "clicks": 747,
        "avgCPC": 0.0362
    },
    "FJ": {
        "countryISO": "FJ",
        "country": "Fiji",
        "impressions": 149696,
        "clicks": 125,
        "avgCPC": 0.0019
    },
    "FK": {
        "countryISO": "FK",
        "country": "Falkland Islands",
        "impressions": 1651,
        "clicks": 0,
        "avgCPC": 0.0019
    },
    "FM": {
        "countryISO": "FM",
        "country": "Micronesia",
        "impressions": 8888,
        "clicks": 7,
        "avgCPC": 0.0022
    },
    "FO": {
        "countryISO": "FO",
        "country": "Faroe Islands",
        "impressions": 7977,
        "clicks": 7,
        "avgCPC": 0.0027
    },
    "FR": {
        "countryISO": "FR",
        "country": "France",
        "impressions": 8145404,
        "clicks": 5638,
        "avgCPC": 0.0499
    },
    "GA": {
        "countryISO": "GA",
        "country": "Gabon",
        "impressions": 183451,
        "clicks": 230,
        "avgCPC": 0.0024
    },
    "GB": {
        "countryISO": "GB",
        "country": "United Kingdom",
        "impressions": 6957532,
        "clicks": 9623,
        "avgCPC": 0.0785
    },
    "GD": {
        "countryISO": "GD",
        "country": "Grenada",
        "impressions": 27629,
        "clicks": 23,
        "avgCPC": 0.0044
    },
    "GE": {
        "countryISO": "GE",
        "country": "Georgia",
        "impressions": 1783797,
        "clicks": 1427,
        "avgCPC": 0.0033
    },
    "GF": {
        "countryISO": "GF",
        "country": "French Guiana",
        "impressions": 65423,
        "clicks": 63,
        "avgCPC": 0.0058
    },
    "GG": {
        "countryISO": "GG",
        "country": "Guernsey",
        "impressions": 6680,
        "clicks": 3,
        "avgCPC": 0.0022
    },
    "GH": {
        "countryISO": "GH",
        "country": "Ghana",
        "impressions": 904095,
        "clicks": 1140,
        "avgCPC": 0.0202
    },
    "GI": {
        "countryISO": "GI",
        "country": "Gibraltar",
        "impressions": 5611,
        "clicks": 1,
        "avgCPC": 0.0027
    },
    "GL": {
        "countryISO": "GL",
        "country": "Greenland",
        "impressions": 8876,
        "clicks": 10,
        "avgCPC": 0.0026
    },
    "GM": {
        "countryISO": "GM",
        "country": "Gambia",
        "impressions": 61532,
        "clicks": 132,
        "avgCPC": 0.0028
    },
    "GN": {
        "countryISO": "GN",
        "country": "Guinea",
        "impressions": 127308,
        "clicks": 308,
        "avgCPC": 0.0145
    },
    "GP": {
        "countryISO": "GP",
        "country": "Guadeloupe",
        "impressions": 40003,
        "clicks": 23,
        "avgCPC": 0.0026
    },
    "GQ": {
        "countryISO": "GQ",
        "country": "Equatorial Guinea",
        "impressions": 18278,
        "clicks": 23,
        "avgCPC": 0.0025
    },
    "GR": {
        "countryISO": "GR",
        "country": "Greece",
        "impressions": 3886587,
        "clicks": 1877,
        "avgCPC": 0.0356
    },
    "GT": {
        "countryISO": "GT",
        "country": "Guatemala",
        "impressions": 1234501,
        "clicks": 3531,
        "avgCPC": 0.0026
    },
    "GU": {
        "countryISO": "GU",
        "country": "Guam",
        "impressions": 27430,
        "clicks": 15,
        "avgCPC": 0.0024
    },
    "GW": {
        "countryISO": "GW",
        "country": "Guinea-Bissau",
        "impressions": 20780,
        "clicks": 43,
        "avgCPC": 0.0026
    },
    "GY": {
        "countryISO": "GY",
        "country": "Guyana",
        "impressions": 153873,
        "clicks": 172,
        "avgCPC": 0.003
    },
    "HK": {
        "countryISO": "HK",
        "country": "Hong Kong",
        "impressions": 554375,
        "clicks": 444,
        "avgCPC": 0.0266
    },
    "HN": {
        "countryISO": "HN",
        "country": "Honduras",
        "impressions": 1275859,
        "clicks": 1184,
        "avgCPC": 0.0025
    },
    "HR": {
        "countryISO": "HR",
        "country": "Croatia",
        "impressions": 3405217,
        "clicks": 1528,
        "avgCPC": 0.0271
    },
    "HT": {
        "countryISO": "HT",
        "country": "Haiti",
        "impressions": 458471,
        "clicks": 1399,
        "avgCPC": 0.0024
    },
    "HU": {
        "countryISO": "HU",
        "country": "Hungary",
        "impressions": 461695,
        "clicks": 362,
        "avgCPC": 0.0145
    },
    "ID": {
        "countryISO": "ID",
        "country": "Indonesia",
        "impressions": 49344502,
        "clicks": 90785,
        "avgCPC": 0.0172
    },
    "IE": {
        "countryISO": "IE",
        "country": "Ireland",
        "impressions": 827210,
        "clicks": 877,
        "avgCPC": 0.0523
    },
    "IL": {
        "countryISO": "IL",
        "country": "Israel",
        "impressions": 1715083,
        "clicks": 1241,
        "avgCPC": 0.0089
    },
    "IM": {
        "countryISO": "IM",
        "country": "Isle of Man",
        "impressions": 8318,
        "clicks": 3,
        "avgCPC": 0.0024
    },
    "IN": {
        "countryISO": "IN",
        "country": "India",
        "impressions": 230119255,
        "clicks": 758989,
        "avgCPC": 0.0058
    },
    "IO": {
        "countryISO": "IO",
        "country": "British Indian Ocean Territory",
        "impressions": 163,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "IS": {
        "countryISO": "IS",
        "country": "Iceland",
        "impressions": 109777,
        "clicks": 49,
        "avgCPC": 0.0132
    },
    "IT": {
        "countryISO": "IT",
        "country": "Italy",
        "impressions": 8968168,
        "clicks": 6754,
        "avgCPC": 0.0183
    },
    "JE": {
        "countryISO": "JE",
        "country": "Jersey",
        "impressions": 16421,
        "clicks": 8,
        "avgCPC": 0.0023
    },
    "JM": {
        "countryISO": "JM",
        "country": "Jamaica",
        "impressions": 392883,
        "clicks": 396,
        "avgCPC": 0.0041
    },
    "JP": {
        "countryISO": "JP",
        "country": "Japan",
        "impressions": 4543654,
        "clicks": 7370,
        "avgCPC": 0.0312
    },
    "KE": {
        "countryISO": "KE",
        "country": "Kenya",
        "impressions": 803384,
        "clicks": 2148,
        "avgCPC": 0.0077
    },
    "KG": {
        "countryISO": "KG",
        "country": "Kyrgyzstan",
        "impressions": 3793459,
        "clicks": 2364,
        "avgCPC": 0.0022
    },
    "KI": {
        "countryISO": "KI",
        "country": "Kiribati",
        "impressions": 2814,
        "clicks": 5,
        "avgCPC": 0.0025
    },
    "KM": {
        "countryISO": "KM",
        "country": "Comoros",
        "impressions": 32338,
        "clicks": 89,
        "avgCPC": 0.0025
    },
    "KN": {
        "countryISO": "KN",
        "country": "Saint Kitts and Nevis",
        "impressions": 10294,
        "clicks": 7,
        "avgCPC": 0.0049
    },
    "KR": {
        "countryISO": "KR",
        "country": "South Korea",
        "impressions": 1701108,
        "clicks": 1679,
        "avgCPC": 0.0117
    },
    "KW": {
        "countryISO": "KW",
        "country": "Kuwait",
        "impressions": 612799,
        "clicks": 744,
        "avgCPC": 0.0094
    },
    "KY": {
        "countryISO": "KY",
        "country": "Cayman Islands",
        "impressions": 31188,
        "clicks": 22,
        "avgCPC": 0.0111
    },
    "KZ": {
        "countryISO": "KZ",
        "country": "Kazakhstan",
        "impressions": 4327788,
        "clicks": 1691,
        "avgCPC": 0.0121
    },
    "LC": {
        "countryISO": "LC",
        "country": "Saint Lucia",
        "impressions": 34383,
        "clicks": 29,
        "avgCPC": 0.0045
    },
    "LI": {
        "countryISO": "LI",
        "country": "Liechtenstein",
        "impressions": 2388,
        "clicks": 0,
        "avgCPC": 0.002
    },
    "LK": {
        "countryISO": "LK",
        "country": "Sri Lanka",
        "impressions": 1913943,
        "clicks": 1721,
        "avgCPC": 0.0013
    },
    "LS": {
        "countryISO": "LS",
        "country": "Lesotho",
        "impressions": 32789,
        "clicks": 82,
        "avgCPC": 0.0027
    },
    "LT": {
        "countryISO": "LT",
        "country": "Lithuania",
        "impressions": 3060653,
        "clicks": 1018,
        "avgCPC": 0.0367
    },
    "LU": {
        "countryISO": "LU",
        "country": "Luxembourg",
        "impressions": 96544,
        "clicks": 59,
        "avgCPC": 0.1071
    },
    "LV": {
        "countryISO": "LV",
        "country": "Latvia",
        "impressions": 825154,
        "clicks": 372,
        "avgCPC": 0.0396
    },
    "MA": {
        "countryISO": "MA",
        "country": "Morocco",
        "impressions": 1711343,
        "clicks": 3048,
        "avgCPC": 0.0028
    },
    "MC": {
        "countryISO": "MC",
        "country": "Monaco",
        "impressions": 6185,
        "clicks": 2,
        "avgCPC": 0.0056
    },
    "MD": {
        "countryISO": "MD",
        "country": "Moldova",
        "impressions": 1464994,
        "clicks": 742,
        "avgCPC": 0.0118
    },
    "ME": {
        "countryISO": "ME",
        "country": "Montenegro",
        "impressions": 201591,
        "clicks": 113,
        "avgCPC": 0.0105
    },
    "MF": {
        "countryISO": "MF",
        "country": "Saint Martin",
        "impressions": 5735,
        "clicks": 3,
        "avgCPC": 0.0042
    },
    "MG": {
        "countryISO": "MG",
        "country": "Madagascar",
        "impressions": 103283,
        "clicks": 244,
        "avgCPC": 0.0028
    },
    "MH": {
        "countryISO": "MH",
        "country": "Marshall Islands",
        "impressions": 2373,
        "clicks": 3,
        "avgCPC": 0.0025
    },
    "MK": {
        "countryISO": "MK",
        "country": "North Macedonia",
        "impressions": 566045,
        "clicks": 256,
        "avgCPC": 0.0029
    },
    "ML": {
        "countryISO": "ML",
        "country": "Mali",
        "impressions": 288504,
        "clicks": 429,
        "avgCPC": 0.0092
    },
    "MN": {
        "countryISO": "MN",
        "country": "Mongolia",
        "impressions": 955617,
        "clicks": 454,
        "avgCPC": 0.0013
    },
    "MO": {
        "countryISO": "MO",
        "country": "Macao",
        "impressions": 39349,
        "clicks": 28,
        "avgCPC": 0.0026
    },
    "MP": {
        "countryISO": "MP",
        "country": "Northern Mariana Islands",
        "impressions": 5568,
        "clicks": 2,
        "avgCPC": 0.0025
    },
    "MQ": {
        "countryISO": "MQ",
        "country": "Martinique",
        "impressions": 74882,
        "clicks": 60,
        "avgCPC": 0.0093
    },
    "MR": {
        "countryISO": "MR",
        "country": "Mauritania",
        "impressions": 188960,
        "clicks": 402,
        "avgCPC": 0.0022
    },
    "MS": {
        "countryISO": "MS",
        "country": "Montserrat",
        "impressions": 883,
        "clicks": 2,
        "avgCPC": 0.0021
    },
    "MT": {
        "countryISO": "MT",
        "country": "Malta",
        "impressions": 87380,
        "clicks": 65,
        "avgCPC": 0.0096
    },
    "MU": {
        "countryISO": "MU",
        "country": "Mauritius",
        "impressions": 613126,
        "clicks": 266,
        "avgCPC": 0.0023
    },
    "MV": {
        "countryISO": "MV",
        "country": "Maldives",
        "impressions": 174529,
        "clicks": 76,
        "avgCPC": 0.0019
    },
    "MW": {
        "countryISO": "MW",
        "country": "Malawi",
        "impressions": 134231,
        "clicks": 828,
        "avgCPC": 0.0065
    },
    "MX": {
        "countryISO": "MX",
        "country": "Mexico",
        "impressions": 8074623,
        "clicks": 24617,
        "avgCPC": 0.009
    },
    "MY": {
        "countryISO": "MY",
        "country": "Malaysia",
        "impressions": 15753135,
        "clicks": 12824,
        "avgCPC": 0.0155
    },
    "MZ": {
        "countryISO": "MZ",
        "country": "Mozambique",
        "impressions": 307270,
        "clicks": 848,
        "avgCPC": 0.0107
    },
    "NA": {
        "countryISO": "NA",
        "country": "Namibia",
        "impressions": 144051,
        "clicks": 352,
        "avgCPC": 0.003
    },
    "NC": {
        "countryISO": "NC",
        "country": "New Caledonia",
        "impressions": 36847,
        "clicks": 27,
        "avgCPC": 0.0064
    },
    "NE": {
        "countryISO": "NE",
        "country": "Niger",
        "impressions": 107220,
        "clicks": 264,
        "avgCPC": 0.0034
    },
    "NI": {
        "countryISO": "NI",
        "country": "Nicaragua",
        "impressions": 755285,
        "clicks": 695,
        "avgCPC": 0.0024
    },
    "NL": {
        "countryISO": "NL",
        "country": "Netherlands",
        "impressions": 5606030,
        "clicks": 6199,
        "avgCPC": 0.0897
    },
    "NO": {
        "countryISO": "NO",
        "country": "Norway",
        "impressions": 1171942,
        "clicks": 957,
        "avgCPC": 0.0619
    },
    "NR": {
        "countryISO": "NR",
        "country": "Nauru",
        "impressions": 1011,
        "clicks": 1,
        "avgCPC": 0.0027
    },
    "NU": {
        "countryISO": "NU",
        "country": "Niue",
        "impressions": 1130,
        "clicks": 1,
        "avgCPC": 0.003
    },
    "NZ": {
        "countryISO": "NZ",
        "country": "New Zealand",
        "impressions": 403036,
        "clicks": 532,
        "avgCPC": 0.0837
    },
    "OM": {
        "countryISO": "OM",
        "country": "Oman",
        "impressions": 654914,
        "clicks": 621,
        "avgCPC": 0.008
    },
    "PA": {
        "countryISO": "PA",
        "country": "Panama",
        "impressions": 650250,
        "clicks": 661,
        "avgCPC": 0.0066
    },
    "PE": {
        "countryISO": "PE",
        "country": "Peru",
        "impressions": 2483012,
        "clicks": 4981,
        "avgCPC": 0.0049
    },
    "PF": {
        "countryISO": "PF",
        "country": "French Polynesia",
        "impressions": 71272,
        "clicks": 58,
        "avgCPC": 0.0142
    },
    "PG": {
        "countryISO": "PG",
        "country": "Papua New Guinea",
        "impressions": 58154,
        "clicks": 237,
        "avgCPC": 0.0037
    },
    "PH": {
        "countryISO": "PH",
        "country": "Philippines",
        "impressions": 12181427,
        "clicks": 14869,
        "avgCPC": 0.0073
    },
    "PK": {
        "countryISO": "PK",
        "country": "Pakistan",
        "impressions": 10403968,
        "clicks": 27065,
        "avgCPC": 0.0048
    },
    "PL": {
        "countryISO": "PL",
        "country": "Poland",
        "impressions": 3712409,
        "clicks": 3437,
        "avgCPC": 0.0195
    },
    "PM": {
        "countryISO": "PM",
        "country": "Saint Pierre and Miquelon",
        "impressions": 2805,
        "clicks": 1,
        "avgCPC": 0.003
    },
    "PR": {
        "countryISO": "PR",
        "country": "Puerto Rico",
        "impressions": 782129,
        "clicks": 432,
        "avgCPC": 0.0206
    },
    "PS": {
        "countryISO": "PS",
        "country": "Palestinian Territory",
        "impressions": 1075546,
        "clicks": 1017,
        "avgCPC": 0.0024
    },
    "PT": {
        "countryISO": "PT",
        "country": "Portugal",
        "impressions": 842530,
        "clicks": 1021,
        "avgCPC": 0.0193
    },
    "PW": {
        "countryISO": "PW",
        "country": "Palau",
        "impressions": 3536,
        "clicks": 1,
        "avgCPC": 0.0023
    },
    "PY": {
        "countryISO": "PY",
        "country": "Paraguay",
        "impressions": 1040537,
        "clicks": 1119,
        "avgCPC": 0.0026
    },
    "RE": {
        "countryISO": "RE",
        "country": "Reunion",
        "impressions": 294215,
        "clicks": 213,
        "avgCPC": 0.0153
    },
    "RO": {
        "countryISO": "RO",
        "country": "Romania",
        "impressions": 1654138,
        "clicks": 830,
        "avgCPC": 0.0141
    },
    "RS": {
        "countryISO": "RS",
        "country": "Serbia",
        "impressions": 1788159,
        "clicks": 1259,
        "avgCPC": 0.0118
    },
    "RW": {
        "countryISO": "RW",
        "country": "Rwanda",
        "impressions": 118824,
        "clicks": 323,
        "avgCPC": 0.0041
    },
    "SA": {
        "countryISO": "SA",
        "country": "Saudi Arabia",
        "impressions": 2866556,
        "clicks": 6655,
        "avgCPC": 0.0108
    },
    "SB": {
        "countryISO": "SB",
        "country": "Solomon Islands",
        "impressions": 8414,
        "clicks": 22,
        "avgCPC": 0.0026
    },
    "SC": {
        "countryISO": "SC",
        "country": "Seychelles",
        "impressions": 12725,
        "clicks": 9,
        "avgCPC": 0.0025
    },
    "SD": {
        "countryISO": "SD",
        "country": "Sudan",
        "impressions": 815608,
        "clicks": 2432,
        "avgCPC": 0.0026
    },
    "SE": {
        "countryISO": "SE",
        "country": "Sweden",
        "impressions": 1101159,
        "clicks": 1126,
        "avgCPC": 0.0305
    },
    "SG": {
        "countryISO": "SG",
        "country": "Singapore",
        "impressions": 2030448,
        "clicks": 2066,
        "avgCPC": 0.0323
    },
    "SH": {
        "countryISO": "SH",
        "country": "Saint Helena",
        "impressions": 899,
        "clicks": 0,
        "avgCPC": 0.003
    },
    "SI": {
        "countryISO": "SI",
        "country": "Slovenia",
        "impressions": 1964256,
        "clicks": 1048,
        "avgCPC": 0.029
    },
    "SK": {
        "countryISO": "SK",
        "country": "Slovakia",
        "impressions": 1098943,
        "clicks": 1629,
        "avgCPC": 0.0323
    },
    "SL": {
        "countryISO": "SL",
        "country": "Sierra Leone",
        "impressions": 72853,
        "clicks": 134,
        "avgCPC": 0.0028
    },
    "SM": {
        "countryISO": "SM",
        "country": "San Marino",
        "impressions": 6323,
        "clicks": 2,
        "avgCPC": 0.0041
    },
    "SN": {
        "countryISO": "SN",
        "country": "Senegal",
        "impressions": 408571,
        "clicks": 1188,
        "avgCPC": 0.0039
    },
    "SR": {
        "countryISO": "SR",
        "country": "Suriname",
        "impressions": 141995,
        "clicks": 151,
        "avgCPC": 0.0024
    },
    "ST": {
        "countryISO": "ST",
        "country": "Sao Tome and Principe",
        "impressions": 6046,
        "clicks": 15,
        "avgCPC": 0.0026
    },
    "SV": {
        "countryISO": "SV",
        "country": "El Salvador",
        "impressions": 1005084,
        "clicks": 1441,
        "avgCPC": 0.0031
    },
    "SX": {
        "countryISO": "SX",
        "country": "Sint Maarten",
        "impressions": 14817,
        "clicks": 6,
        "avgCPC": 0.0025
    },
    "SZ": {
        "countryISO": "SZ",
        "country": "Eswatini",
        "impressions": 15762,
        "clicks": 43,
        "avgCPC": 0.0033
    },
    "TC": {
        "countryISO": "TC",
        "country": "Turks and Caicos Islands",
        "impressions": 7790,
        "clicks": 4,
        "avgCPC": 0.0024
    },
    "TD": {
        "countryISO": "TD",
        "country": "Chad",
        "impressions": 63432,
        "clicks": 139,
        "avgCPC": 0.0027
    },
    "TG": {
        "countryISO": "TG",
        "country": "Togo",
        "impressions": 221497,
        "clicks": 430,
        "avgCPC": 0.0023
    },
    "TH": {
        "countryISO": "TH",
        "country": "Thailand",
        "impressions": 25458834,
        "clicks": 26390,
        "avgCPC": 0.0282
    },
    "TJ": {
        "countryISO": "TJ",
        "country": "Tajikistan",
        "impressions": 1688051,
        "clicks": 2042,
        "avgCPC": 0.0024
    },
    "TK": {
        "countryISO": "TK",
        "country": "Tokelau",
        "impressions": 244,
        "clicks": 0,
        "avgCPC": 0.003
    },
    "TL": {
        "countryISO": "TL",
        "country": "Timor Leste",
        "impressions": 89556,
        "clicks": 91,
        "avgCPC": 0.0015
    },
    "TM": {
        "countryISO": "TM",
        "country": "Turkmenistan",
        "impressions": 6139,
        "clicks": 10,
        "avgCPC": 0.0015
    },
    "TN": {
        "countryISO": "TN",
        "country": "Tunisia",
        "impressions": 444032,
        "clicks": 584,
        "avgCPC": 0.0032
    },
    "TO": {
        "countryISO": "TO",
        "country": "Tonga",
        "impressions": 7819,
        "clicks": 25,
        "avgCPC": 0.0026
    },
    "TR": {
        "countryISO": "TR",
        "country": "Turkey",
        "impressions": 2070199,
        "clicks": 2467,
        "avgCPC": 0.0048
    },
    "TT": {
        "countryISO": "TT",
        "country": "Trinidad and Tobago",
        "impressions": 187770,
        "clicks": 158,
        "avgCPC": 0.0064
    },
    "TV": {
        "countryISO": "TV",
        "country": "Tuvalu",
        "impressions": 777,
        "clicks": 1,
        "avgCPC": 0.0031
    },
    "TW": {
        "countryISO": "TW",
        "country": "Taiwan",
        "impressions": 2238998,
        "clicks": 1499,
        "avgCPC": 0.0352
    },
    "TZ": {
        "countryISO": "TZ",
        "country": "Tanzania",
        "impressions": 578494,
        "clicks": 1887,
        "avgCPC": 0.0034
    },
    "UA": {
        "countryISO": "UA",
        "country": "Ukraine",
        "impressions": 522931,
        "clicks": 195,
        "avgCPC": 0.0142
    },
    "US": {
        "countryISO": "US",
        "country": "United States",
        "impressions": 33319476,
        "clicks": 30605,
        "avgCPC": 0.1287
    },
    "UY": {
        "countryISO": "UY",
        "country": "Uruguay",
        "impressions": 675100,
        "clicks": 631,
        "avgCPC": 0.0032
    },
    "UZ": {
        "countryISO": "UZ",
        "country": "Uzbekistan",
        "impressions": 4457169,
        "clicks": 3115,
        "avgCPC": 0.0282
    },
    "VA": {
        "countryISO": "VA",
        "country": "Vatican",
        "impressions": 346,
        "clicks": 0,
        "avgCPC": 0.003
    },
    "VC": {
        "countryISO": "VC",
        "country": "Saint Vincent and the Grenadines",
        "impressions": 16071,
        "clicks": 13,
        "avgCPC": 0.0054
    },
    "VE": {
        "countryISO": "VE",
        "country": "Venezuela",
        "impressions": 657186,
        "clicks": 1671,
        "avgCPC": 0.0039
    },
    "VG": {
        "countryISO": "VG",
        "country": "British Virgin Islands",
        "impressions": 3020,
        "clicks": 3,
        "avgCPC": 0.0025
    },
    "VI": {
        "countryISO": "VI",
        "country": "U.S. Virgin Islands",
        "impressions": 12936,
        "clicks": 6,
        "avgCPC": 0.0027
    },
    "VN": {
        "countryISO": "VN",
        "country": "Vietnam",
        "impressions": 9405662,
        "clicks": 3778,
        "avgCPC": 0.0025
    },
    "WF": {
        "countryISO": "WF",
        "country": "Wallis and Futuna",
        "impressions": 885,
        "clicks": 0,
        "avgCPC": 0.0032
    },
    "WS": {
        "countryISO": "WS",
        "country": "Samoa",
        "impressions": 7299,
        "clicks": 7,
        "avgCPC": 0.0025
    },
    "XK": {
        "countryISO": "XK",
        "country": "Kosovo",
        "impressions": 51733,
        "clicks": 39,
        "avgCPC": 0.0013
    },
    "YT": {
        "countryISO": "YT",
        "country": "Mayotte",
        "impressions": 14810,
        "clicks": 12,
        "avgCPC": 0.0024
    },
    "ZA": {
        "countryISO": "ZA",
        "country": "South Africa",
        "impressions": 1956267,
        "clicks": 3862,
        "avgCPC": 0.0159
    },
    "ZM": {
        "countryISO": "ZM",
        "country": "Zambia",
        "impressions": 94783,
        "clicks": 171,
        "avgCPC": 0.0044
    }
}
};
