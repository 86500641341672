export { svgMapStatPerCountryOnclick };

const svgMapStatPerCountryOnclick = {
    data: {
        impressions: {
            name: 'Impressions',
        },

        avgCPM: {
            name: 'CPM, $',
        },
    },
    applyData: 'impressions',
    values: {
    "AD": {
        "countryISO": "AD",
        "country": "Andorra",
        "impressions": 380,
        "avgCPM": 0.1098
    },
    "AE": {
        "countryISO": "AE",
        "country": "United Arab Emirates",
        "impressions": 45360,
        "avgCPM": 0.392
    },
    "AG": {
        "countryISO": "AG",
        "country": "Antigua and Barbuda",
        "impressions": 120,
        "avgCPM": 0.12
    },
    "AI": {
        "countryISO": "AI",
        "country": "Anguilla",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "AL": {
        "countryISO": "AL",
        "country": "Albania",
        "impressions": 122200,
        "avgCPM": 0.34
    },
    "AM": {
        "countryISO": "AM",
        "country": "Armenia",
        "impressions": 260,
        "avgCPM": 0.12
    },
    "AO": {
        "countryISO": "AO",
        "country": "Angola",
        "impressions": 4720,
        "avgCPM": 0.1542
    },
    "AR": {
        "countryISO": "AR",
        "country": "Argentina",
        "impressions": 523920,
        "avgCPM": 0.35
    },
    "AT": {
        "countryISO": "AT",
        "country": "Austria",
        "impressions": 517400,
        "avgCPM": 1.3453
    },
    "AU": {
        "countryISO": "AU",
        "country": "Australia",
        "impressions": 747540,
        "avgCPM": 1.2205
    },
    "AW": {
        "countryISO": "AW",
        "country": "Aruba",
        "impressions": 220,
        "avgCPM": 0.12
    },
    "AX": {
        "countryISO": "AX",
        "country": "Aland Islands",
        "impressions": 20,
        "avgCPM": 0.11
    },
    "AZ": {
        "countryISO": "AZ",
        "country": "Azerbaijan",
        "impressions": 3200,
        "avgCPM": 0.12
    },
    "BB": {
        "countryISO": "BB",
        "country": "Barbados",
        "impressions": 1020,
        "avgCPM": 0.12
    },
    "BE": {
        "countryISO": "BE",
        "country": "Belgium",
        "impressions": 74960,
        "avgCPM": 1.764
    },
    "BF": {
        "countryISO": "BF",
        "country": "Burkina Faso",
        "impressions": 1760,
        "avgCPM": 0.7652
    },
    "BG": {
        "countryISO": "BG",
        "country": "Bulgaria",
        "impressions": 1425720,
        "avgCPM": 2.1113
    },
    "BH": {
        "countryISO": "BH",
        "country": "Bahrain",
        "impressions": 1720,
        "avgCPM": 0.12
    },
    "BI": {
        "countryISO": "BI",
        "country": "Burundi",
        "impressions": 20,
        "avgCPM": 0.1133
    },
    "BJ": {
        "countryISO": "BJ",
        "country": "Benin",
        "impressions": 4840,
        "avgCPM": 0.1557
    },
    "BL": {
        "countryISO": "BL",
        "country": "Saint Barthelemy",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "BM": {
        "countryISO": "BM",
        "country": "Bermuda",
        "impressions": 100,
        "avgCPM": 0.12
    },
    "BN": {
        "countryISO": "BN",
        "country": "Brunei",
        "impressions": 1320,
        "avgCPM": 0.12
    },
    "BO": {
        "countryISO": "BO",
        "country": "Bolivia",
        "impressions": 33660,
        "avgCPM": 0.1066
    },
    "BQ": {
        "countryISO": "BQ",
        "country": "Bonaire, Saint Eustatius and Saba ",
        "impressions": 60,
        "avgCPM": 0.12
    },
    "BR": {
        "countryISO": "BR",
        "country": "Brazil",
        "impressions": 1766520,
        "avgCPM": 0.3566
    },
    "BS": {
        "countryISO": "BS",
        "country": "Bahamas",
        "impressions": 660,
        "avgCPM": 0.12
    },
    "BT": {
        "countryISO": "BT",
        "country": "Bhutan",
        "impressions": 520,
        "avgCPM": 0.12
    },
    "BW": {
        "countryISO": "BW",
        "country": "Botswana",
        "impressions": 620,
        "avgCPM": 0.1706
    },
    "BZ": {
        "countryISO": "BZ",
        "country": "Belize",
        "impressions": 840,
        "avgCPM": 0.12
    },
    "CA": {
        "countryISO": "CA",
        "country": "Canada",
        "impressions": 1272240,
        "avgCPM": 0.4999
    },
    "CF": {
        "countryISO": "CF",
        "country": "Central African Republic",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "CH": {
        "countryISO": "CH",
        "country": "Switzerland",
        "impressions": 648060,
        "avgCPM": 1
    },
    "CI": {
        "countryISO": "CI",
        "country": "Ivory Coast",
        "impressions": 115760,
        "avgCPM": 1.8
    },
    "CK": {
        "countryISO": "CK",
        "country": "Cook Islands",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "CL": {
        "countryISO": "CL",
        "country": "Chile",
        "impressions": 1257560,
        "avgCPM": 0.5247
    },
    "CM": {
        "countryISO": "CM",
        "country": "Cameroon",
        "impressions": 12980,
        "avgCPM": 0.3298
    },
    "CN": {
        "countryISO": "CN",
        "country": "China",
        "impressions": 631120,
        "avgCPM": 0.9042
    },
    "CO": {
        "countryISO": "CO",
        "country": "Colombia",
        "impressions": 1666080,
        "avgCPM": 0.4494
    },
    "CR": {
        "countryISO": "CR",
        "country": "Costa Rica",
        "impressions": 3240,
        "avgCPM": 0.1434
    },
    "CV": {
        "countryISO": "CV",
        "country": "Cabo Verde",
        "impressions": 180,
        "avgCPM": 0.12
    },
    "CW": {
        "countryISO": "CW",
        "country": "Curacao",
        "impressions": 260,
        "avgCPM": 0.12
    },
    "CY": {
        "countryISO": "CY",
        "country": "Cyprus",
        "impressions": 142700,
        "avgCPM": 2.7776
    },
    "CZ": {
        "countryISO": "CZ",
        "country": "Czechia",
        "impressions": 290260,
        "avgCPM": 0.5
    },
    "DE": {
        "countryISO": "DE",
        "country": "Germany",
        "impressions": 1539700,
        "avgCPM": 1.4802
    },
    "DJ": {
        "countryISO": "DJ",
        "country": "Djibouti",
        "impressions": 240,
        "avgCPM": 0.12
    },
    "DK": {
        "countryISO": "DK",
        "country": "Denmark",
        "impressions": 16980,
        "avgCPM": 0.1941
    },
    "DM": {
        "countryISO": "DM",
        "country": "Dominica",
        "impressions": 280,
        "avgCPM": 0.12
    },
    "DO": {
        "countryISO": "DO",
        "country": "Dominican Republic",
        "impressions": 22680,
        "avgCPM": 0.1085
    },
    "EC": {
        "countryISO": "EC",
        "country": "Ecuador",
        "impressions": 16700,
        "avgCPM": 0.12
    },
    "EE": {
        "countryISO": "EE",
        "country": "Estonia",
        "impressions": 41760,
        "avgCPM": 1.8
    },
    "EG": {
        "countryISO": "EG",
        "country": "Egypt",
        "impressions": 400000,
        "avgCPM": 0.1064
    },
    "ES": {
        "countryISO": "ES",
        "country": "Spain",
        "impressions": 4015460,
        "avgCPM": 0.5109
    },
    "ET": {
        "countryISO": "ET",
        "country": "Ethiopia",
        "impressions": 1020,
        "avgCPM": 0.12
    },
    "FI": {
        "countryISO": "FI",
        "country": "Finland",
        "impressions": 178320,
        "avgCPM": 1.5305
    },
    "FJ": {
        "countryISO": "FJ",
        "country": "Fiji",
        "impressions": 900,
        "avgCPM": 0.12
    },
    "FK": {
        "countryISO": "FK",
        "country": "Falkland Islands",
        "impressions": 20,
        "avgCPM": 0.11
    },
    "FM": {
        "countryISO": "FM",
        "country": "Micronesia",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "FO": {
        "countryISO": "FO",
        "country": "Faroe Islands",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "FR": {
        "countryISO": "FR",
        "country": "France",
        "impressions": 3338660,
        "avgCPM": 0.7605
    },
    "GA": {
        "countryISO": "GA",
        "country": "Gabon",
        "impressions": 62520,
        "avgCPM": 0.48
    },
    "GB": {
        "countryISO": "GB",
        "country": "United Kingdom",
        "impressions": 4407820,
        "avgCPM": 2.597
    },
    "GD": {
        "countryISO": "GD",
        "country": "Grenada",
        "impressions": 260,
        "avgCPM": 0.12
    },
    "GE": {
        "countryISO": "GE",
        "country": "Georgia",
        "impressions": 800,
        "avgCPM": 0.12
    },
    "GF": {
        "countryISO": "GF",
        "country": "French Guiana",
        "impressions": 100,
        "avgCPM": 0.12
    },
    "GG": {
        "countryISO": "GG",
        "country": "Guernsey",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "GH": {
        "countryISO": "GH",
        "country": "Ghana",
        "impressions": 216100,
        "avgCPM": 1.2299
    },
    "GI": {
        "countryISO": "GI",
        "country": "Gibraltar",
        "impressions": 60,
        "avgCPM": 0.12
    },
    "GL": {
        "countryISO": "GL",
        "country": "Greenland",
        "impressions": 100,
        "avgCPM": 0.1122
    },
    "GM": {
        "countryISO": "GM",
        "country": "Gambia",
        "impressions": 140,
        "avgCPM": 0.12
    },
    "GN": {
        "countryISO": "GN",
        "country": "Guinea",
        "impressions": 420,
        "avgCPM": 1.4718
    },
    "GP": {
        "countryISO": "GP",
        "country": "Guadeloupe",
        "impressions": 180,
        "avgCPM": 0.12
    },
    "GQ": {
        "countryISO": "GQ",
        "country": "Equatorial Guinea",
        "impressions": 40,
        "avgCPM": 0.1092
    },
    "GR": {
        "countryISO": "GR",
        "country": "Greece",
        "impressions": 1639000,
        "avgCPM": 0.7
    },
    "GT": {
        "countryISO": "GT",
        "country": "Guatemala",
        "impressions": 8640,
        "avgCPM": 0.12
    },
    "GU": {
        "countryISO": "GU",
        "country": "Guam",
        "impressions": 520,
        "avgCPM": 0.12
    },
    "GW": {
        "countryISO": "GW",
        "country": "Guinea-Bissau",
        "impressions": 20,
        "avgCPM": 0.112
    },
    "GY": {
        "countryISO": "GY",
        "country": "Guyana",
        "impressions": 1000,
        "avgCPM": 0.12
    },
    "HK": {
        "countryISO": "HK",
        "country": "Hong Kong",
        "impressions": 46500,
        "avgCPM": 0.1903
    },
    "HN": {
        "countryISO": "HN",
        "country": "Honduras",
        "impressions": 7480,
        "avgCPM": 0.12
    },
    "HR": {
        "countryISO": "HR",
        "country": "Croatia",
        "impressions": 679520,
        "avgCPM": 1.2
    },
    "HT": {
        "countryISO": "HT",
        "country": "Haiti",
        "impressions": 1320,
        "avgCPM": 0.12
    },
    "HU": {
        "countryISO": "HU",
        "country": "Hungary",
        "impressions": 2324680,
        "avgCPM": 2.2483
    },
    "ID": {
        "countryISO": "ID",
        "country": "Indonesia",
        "impressions": 2685820,
        "avgCPM": 0.1652
    },
    "IE": {
        "countryISO": "IE",
        "country": "Ireland",
        "impressions": 15740,
        "avgCPM": 0.1546
    },
    "IL": {
        "countryISO": "IL",
        "country": "Israel",
        "impressions": 1200,
        "avgCPM": 0.1925
    },
    "IM": {
        "countryISO": "IM",
        "country": "Isle of Man",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "IN": {
        "countryISO": "IN",
        "country": "India",
        "impressions": 8833600,
        "avgCPM": 0.2064
    },
    "IO": {
        "countryISO": "IO",
        "country": "British Indian Ocean Territory",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "IS": {
        "countryISO": "IS",
        "country": "Iceland",
        "impressions": 3960,
        "avgCPM": 0.1313
    },
    "IT": {
        "countryISO": "IT",
        "country": "Italy",
        "impressions": 1388100,
        "avgCPM": 0.3038
    },
    "JE": {
        "countryISO": "JE",
        "country": "Jersey",
        "impressions": 60,
        "avgCPM": 0.1129
    },
    "JM": {
        "countryISO": "JM",
        "country": "Jamaica",
        "impressions": 6420,
        "avgCPM": 0.12
    },
    "JP": {
        "countryISO": "JP",
        "country": "Japan",
        "impressions": 18306320,
        "avgCPM": 1.5965
    },
    "KE": {
        "countryISO": "KE",
        "country": "Kenya",
        "impressions": 399440,
        "avgCPM": 0.8014
    },
    "KG": {
        "countryISO": "KG",
        "country": "Kyrgyzstan",
        "impressions": 1560,
        "avgCPM": 0.12
    },
    "KI": {
        "countryISO": "KI",
        "country": "Kiribati",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "KM": {
        "countryISO": "KM",
        "country": "Comoros",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "KN": {
        "countryISO": "KN",
        "country": "Saint Kitts and Nevis",
        "impressions": 100,
        "avgCPM": 0.12
    },
    "KR": {
        "countryISO": "KR",
        "country": "South Korea",
        "impressions": 1445860,
        "avgCPM": 2
    },
    "KW": {
        "countryISO": "KW",
        "country": "Kuwait",
        "impressions": 133640,
        "avgCPM": 0.41
    },
    "KY": {
        "countryISO": "KY",
        "country": "Cayman Islands",
        "impressions": 140,
        "avgCPM": 0.12
    },
    "KZ": {
        "countryISO": "KZ",
        "country": "Kazakhstan",
        "impressions": 246220,
        "avgCPM": 0.1778
    },
    "LC": {
        "countryISO": "LC",
        "country": "Saint Lucia",
        "impressions": 380,
        "avgCPM": 0.12
    },
    "LI": {
        "countryISO": "LI",
        "country": "Liechtenstein",
        "impressions": 340,
        "avgCPM": 0.1742
    },
    "LK": {
        "countryISO": "LK",
        "country": "Sri Lanka",
        "impressions": 382920,
        "avgCPM": 0.3763
    },
    "LS": {
        "countryISO": "LS",
        "country": "Lesotho",
        "impressions": 360,
        "avgCPM": 0.1115
    },
    "LT": {
        "countryISO": "LT",
        "country": "Lithuania",
        "impressions": 85540,
        "avgCPM": 1
    },
    "LU": {
        "countryISO": "LU",
        "country": "Luxembourg",
        "impressions": 12020,
        "avgCPM": 0.1858
    },
    "LV": {
        "countryISO": "LV",
        "country": "Latvia",
        "impressions": 7160,
        "avgCPM": 0.1156
    },
    "MA": {
        "countryISO": "MA",
        "country": "Morocco",
        "impressions": 459380,
        "avgCPM": 0.1452
    },
    "MC": {
        "countryISO": "MC",
        "country": "Monaco",
        "impressions": 80,
        "avgCPM": 0.1206
    },
    "MD": {
        "countryISO": "MD",
        "country": "Moldova",
        "impressions": 2220,
        "avgCPM": 0.134
    },
    "ME": {
        "countryISO": "ME",
        "country": "Montenegro",
        "impressions": 21700,
        "avgCPM": 0.34
    },
    "MF": {
        "countryISO": "MF",
        "country": "Saint Martin",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "MG": {
        "countryISO": "MG",
        "country": "Madagascar",
        "impressions": 1160,
        "avgCPM": 0.12
    },
    "MH": {
        "countryISO": "MH",
        "country": "Marshall Islands",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "MK": {
        "countryISO": "MK",
        "country": "North Macedonia",
        "impressions": 1620,
        "avgCPM": 0.1
    },
    "ML": {
        "countryISO": "ML",
        "country": "Mali",
        "impressions": 35560,
        "avgCPM": 1.5
    },
    "MN": {
        "countryISO": "MN",
        "country": "Mongolia",
        "impressions": 1000,
        "avgCPM": 0.12
    },
    "MO": {
        "countryISO": "MO",
        "country": "Macao",
        "impressions": 160,
        "avgCPM": 0.2
    },
    "MP": {
        "countryISO": "MP",
        "country": "Northern Mariana Islands",
        "impressions": 180,
        "avgCPM": 0.12
    },
    "MQ": {
        "countryISO": "MQ",
        "country": "Martinique",
        "impressions": 160,
        "avgCPM": 0.12
    },
    "MR": {
        "countryISO": "MR",
        "country": "Mauritania",
        "impressions": 500,
        "avgCPM": 0.12
    },
    "MS": {
        "countryISO": "MS",
        "country": "Montserrat",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "MT": {
        "countryISO": "MT",
        "country": "Malta",
        "impressions": 1120,
        "avgCPM": 0.1067
    },
    "MU": {
        "countryISO": "MU",
        "country": "Mauritius",
        "impressions": 7300,
        "avgCPM": 0.1237
    },
    "MV": {
        "countryISO": "MV",
        "country": "Maldives",
        "impressions": 900,
        "avgCPM": 0.12
    },
    "MW": {
        "countryISO": "MW",
        "country": "Malawi",
        "impressions": 37380,
        "avgCPM": 1.2
    },
    "MX": {
        "countryISO": "MX",
        "country": "Mexico",
        "impressions": 1016900,
        "avgCPM": 0.55
    },
    "MY": {
        "countryISO": "MY",
        "country": "Malaysia",
        "impressions": 1250920,
        "avgCPM": 0.9805
    },
    "MZ": {
        "countryISO": "MZ",
        "country": "Mozambique",
        "impressions": 760,
        "avgCPM": 0.51
    },
    "NA": {
        "countryISO": "NA",
        "country": "Namibia",
        "impressions": 600,
        "avgCPM": 0.12
    },
    "NC": {
        "countryISO": "NC",
        "country": "New Caledonia",
        "impressions": 620,
        "avgCPM": 0.1128
    },
    "NE": {
        "countryISO": "NE",
        "country": "Niger",
        "impressions": 100,
        "avgCPM": 0.12
    },
    "NI": {
        "countryISO": "NI",
        "country": "Nicaragua",
        "impressions": 3120,
        "avgCPM": 0.12
    },
    "NL": {
        "countryISO": "NL",
        "country": "Netherlands",
        "impressions": 196160,
        "avgCPM": 1.616
    },
    "NO": {
        "countryISO": "NO",
        "country": "Norway",
        "impressions": 1297700,
        "avgCPM": 2.7194
    },
    "NR": {
        "countryISO": "NR",
        "country": "Nauru",
        "impressions": 20,
        "avgCPM": 0.12
    },
    "NZ": {
        "countryISO": "NZ",
        "country": "New Zealand",
        "impressions": 54100,
        "avgCPM": 1.0325
    },
    "OM": {
        "countryISO": "OM",
        "country": "Oman",
        "impressions": 17220,
        "avgCPM": 0.1296
    },
    "PA": {
        "countryISO": "PA",
        "country": "Panama",
        "impressions": 3380,
        "avgCPM": 0.146
    },
    "PE": {
        "countryISO": "PE",
        "country": "Peru",
        "impressions": 59660,
        "avgCPM": 0.4
    },
    "PF": {
        "countryISO": "PF",
        "country": "French Polynesia",
        "impressions": 280,
        "avgCPM": 0.12
    },
    "PG": {
        "countryISO": "PG",
        "country": "Papua New Guinea",
        "impressions": 280,
        "avgCPM": 0.1121
    },
    "PH": {
        "countryISO": "PH",
        "country": "Philippines",
        "impressions": 4614860,
        "avgCPM": 0.3276
    },
    "PK": {
        "countryISO": "PK",
        "country": "Pakistan",
        "impressions": 1200400,
        "avgCPM": 0.2019
    },
    "PL": {
        "countryISO": "PL",
        "country": "Poland",
        "impressions": 1172960,
        "avgCPM": 1
    },
    "PM": {
        "countryISO": "PM",
        "country": "Saint Pierre and Miquelon",
        "impressions": 20,
        "avgCPM": 0.02
    },
    "PR": {
        "countryISO": "PR",
        "country": "Puerto Rico",
        "impressions": 19400,
        "avgCPM": 0.5
    },
    "PS": {
        "countryISO": "PS",
        "country": "Palestinian Territory",
        "impressions": 7320,
        "avgCPM": 0.12
    },
    "PT": {
        "countryISO": "PT",
        "country": "Portugal",
        "impressions": 1053200,
        "avgCPM": 0.5815
    },
    "PW": {
        "countryISO": "PW",
        "country": "Palau",
        "impressions": 80,
        "avgCPM": 0.12
    },
    "PY": {
        "countryISO": "PY",
        "country": "Paraguay",
        "impressions": 20680,
        "avgCPM": 0.1236
    },
    "RE": {
        "countryISO": "RE",
        "country": "Reunion",
        "impressions": 760,
        "avgCPM": 0.12
    },
    "RO": {
        "countryISO": "RO",
        "country": "Romania",
        "impressions": 2239020,
        "avgCPM": 0.572
    },
    "RS": {
        "countryISO": "RS",
        "country": "Serbia",
        "impressions": 16660,
        "avgCPM": 0.106
    },
    "RW": {
        "countryISO": "RW",
        "country": "Rwanda",
        "impressions": 480,
        "avgCPM": 0.1135
    },
    "SA": {
        "countryISO": "SA",
        "country": "Saudi Arabia",
        "impressions": 3450640,
        "avgCPM": 0.2869
    },
    "SB": {
        "countryISO": "SB",
        "country": "Solomon Islands",
        "impressions": 120,
        "avgCPM": 0.12
    },
    "SC": {
        "countryISO": "SC",
        "country": "Seychelles",
        "impressions": 260,
        "avgCPM": 0.12
    },
    "SD": {
        "countryISO": "SD",
        "country": "Sudan",
        "impressions": 1720,
        "avgCPM": 0.12
    },
    "SE": {
        "countryISO": "SE",
        "country": "Sweden",
        "impressions": 194060,
        "avgCPM": 1.0986
    },
    "SG": {
        "countryISO": "SG",
        "country": "Singapore",
        "impressions": 467480,
        "avgCPM": 0.6723
    },
    "SI": {
        "countryISO": "SI",
        "country": "Slovenia",
        "impressions": 40780,
        "avgCPM": 0.3067
    },
    "SK": {
        "countryISO": "SK",
        "country": "Slovakia",
        "impressions": 503060,
        "avgCPM": 1
    },
    "SL": {
        "countryISO": "SL",
        "country": "Sierra Leone",
        "impressions": 60,
        "avgCPM": 0.1
    },
    "SM": {
        "countryISO": "SM",
        "country": "San Marino",
        "impressions": 60,
        "avgCPM": 0.1072
    },
    "SN": {
        "countryISO": "SN",
        "country": "Senegal",
        "impressions": 6300,
        "avgCPM": 0.8789
    },
    "SR": {
        "countryISO": "SR",
        "country": "Suriname",
        "impressions": 560,
        "avgCPM": 0.12
    },
    "ST": {
        "countryISO": "ST",
        "country": "Sao Tome and Principe",
        "impressions": 20,
        "avgCPM": 0.1
    },
    "SV": {
        "countryISO": "SV",
        "country": "El Salvador",
        "impressions": 3300,
        "avgCPM": 0.12
    },
    "SX": {
        "countryISO": "SX",
        "country": "Sint Maarten",
        "impressions": 140,
        "avgCPM": 0.12
    },
    "SZ": {
        "countryISO": "SZ",
        "country": "Eswatini",
        "impressions": 180,
        "avgCPM": 0.1121
    },
    "TC": {
        "countryISO": "TC",
        "country": "Turks and Caicos Islands",
        "impressions": 60,
        "avgCPM": 0.12
    },
    "TD": {
        "countryISO": "TD",
        "country": "Chad",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "TG": {
        "countryISO": "TG",
        "country": "Togo",
        "impressions": 17800,
        "avgCPM": 0.48
    },
    "TH": {
        "countryISO": "TH",
        "country": "Thailand",
        "impressions": 463540,
        "avgCPM": 1.6647
    },
    "TJ": {
        "countryISO": "TJ",
        "country": "Tajikistan",
        "impressions": 60,
        "avgCPM": 0.1
    },
    "TL": {
        "countryISO": "TL",
        "country": "Timor Leste",
        "impressions": 80,
        "avgCPM": 0.12
    },
    "TM": {
        "countryISO": "TM",
        "country": "Turkmenistan",
        "impressions": 300,
        "avgCPM": 0.1
    },
    "TN": {
        "countryISO": "TN",
        "country": "Tunisia",
        "impressions": 229860,
        "avgCPM": 0.3095
    },
    "TO": {
        "countryISO": "TO",
        "country": "Tonga",
        "impressions": 60,
        "avgCPM": 0.12
    },
    "TR": {
        "countryISO": "TR",
        "country": "Turkey",
        "impressions": 157320,
        "avgCPM": 0.2769
    },
    "TT": {
        "countryISO": "TT",
        "country": "Trinidad and Tobago",
        "impressions": 8560,
        "avgCPM": 0.1164
    },
    "TV": {
        "countryISO": "TV",
        "country": "Tuvalu",
        "impressions": 18,
        "avgCPM": 0.25
    },
    "TW": {
        "countryISO": "TW",
        "country": "Taiwan",
        "impressions": 36500,
        "avgCPM": 0.1064
    },
    "TZ": {
        "countryISO": "TZ",
        "country": "Tanzania",
        "impressions": 1180,
        "avgCPM": 0.1528
    },
    "UA": {
        "countryISO": "UA",
        "country": "Ukraine",
        "impressions": 705060,
        "avgCPM": 0.9342
    },
    "US": {
        "countryISO": "US",
        "country": "United States",
        "impressions": 1207940,
        "avgCPM": 1.2084
    },
    "UY": {
        "countryISO": "UY",
        "country": "Uruguay",
        "impressions": 3780,
        "avgCPM": 0.151
    },
    "UZ": {
        "countryISO": "UZ",
        "country": "Uzbekistan",
        "impressions": 820,
        "avgCPM": 0.12
    },
    "VC": {
        "countryISO": "VC",
        "country": "Saint Vincent and the Grenadines",
        "impressions": 120,
        "avgCPM": 0.12
    },
    "VE": {
        "countryISO": "VE",
        "country": "Venezuela",
        "impressions": 19560,
        "avgCPM": 0.12
    },
    "VG": {
        "countryISO": "VG",
        "country": "British Virgin Islands",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "VI": {
        "countryISO": "VI",
        "country": "U.S. Virgin Islands",
        "impressions": 60,
        "avgCPM": 0.12
    },
    "VN": {
        "countryISO": "VN",
        "country": "Vietnam",
        "impressions": 71880,
        "avgCPM": 0.1175
    },
    "WF": {
        "countryISO": "WF",
        "country": "Wallis and Futuna",
        "impressions": 13,
        "avgCPM": 0.27
    },
    "WS": {
        "countryISO": "WS",
        "country": "Samoa",
        "impressions": 40,
        "avgCPM": 0.12
    },
    "XK": {
        "countryISO": "XK",
        "country": "Kosovo",
        "impressions": 860,
        "avgCPM": 0.1102
    },
    "YT": {
        "countryISO": "YT",
        "country": "Mayotte",
        "impressions": 80,
        "avgCPM": 0.12
    },
    "ZA": {
        "countryISO": "ZA",
        "country": "South Africa",
        "impressions": 657220,
        "avgCPM": 0.1867
    },
    "ZM": {
        "countryISO": "ZM",
        "country": "Zambia",
        "impressions": 95420,
        "avgCPM": 1.75
    }
}
};
