export { svgMapStatPerCountryIPP };

const svgMapStatPerCountryIPP = {
    data: {
        impressions: {
            name: 'Impressions',
        },
        clicks: {
            name: 'Clicks',
        },
        avgCPC: {
            name: 'CPC, $',
        },
    },
    applyData: 'clicks',
    values: {
    "AD": {
        "countryISO": "AD",
        "country": "Andorra",
        "impressions": 137,
        "clicks": 3,
        "avgCPC": 0.001
    },
    "AE": {
        "countryISO": "AE",
        "country": "United Arab Emirates",
        "impressions": 11841,
        "clicks": 218,
        "avgCPC": 0.008
    },
    "AG": {
        "countryISO": "AG",
        "country": "Antigua and Barbuda",
        "impressions": 127,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "AI": {
        "countryISO": "AI",
        "country": "Anguilla",
        "impressions": 94,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "AL": {
        "countryISO": "AL",
        "country": "Albania",
        "impressions": 1449,
        "clicks": 39,
        "avgCPC": 0.003
    },
    "AM": {
        "countryISO": "AM",
        "country": "Armenia",
        "impressions": 199,
        "clicks": 4,
        "avgCPC": 0.003
    },
    "AO": {
        "countryISO": "AO",
        "country": "Angola",
        "impressions": 132,
        "clicks": 3,
        "avgCPC": 0.003
    },
    "AR": {
        "countryISO": "AR",
        "country": "Argentina",
        "impressions": 26041,
        "clicks": 478,
        "avgCPC": 0.003
    },
    "AT": {
        "countryISO": "AT",
        "country": "Austria",
        "impressions": 47844,
        "clicks": 631,
        "avgCPC": 0.008
    },
    "AU": {
        "countryISO": "AU",
        "country": "Australia",
        "impressions": 64638,
        "clicks": 1164,
        "avgCPC": 0.019
    },
    "AW": {
        "countryISO": "AW",
        "country": "Aruba",
        "impressions": 133,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "AX": {
        "countryISO": "AX",
        "country": "Aland Islands",
        "impressions": 16,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "AZ": {
        "countryISO": "AZ",
        "country": "Azerbaijan",
        "impressions": 715,
        "clicks": 15,
        "avgCPC": 0.004
    },
    "BB": {
        "countryISO": "BB",
        "country": "Barbados",
        "impressions": 611,
        "clicks": 14,
        "avgCPC": 0.001
    },
    "BE": {
        "countryISO": "BE",
        "country": "Belgium",
        "impressions": 118591,
        "clicks": 1893,
        "avgCPC": 0.01
    },
    "BF": {
        "countryISO": "BF",
        "country": "Burkina Faso",
        "impressions": 84,
        "clicks": 3,
        "avgCPC": 0.004
    },
    "BG": {
        "countryISO": "BG",
        "country": "Bulgaria",
        "impressions": 17503,
        "clicks": 260,
        "avgCPC": 0.009
    },
    "BH": {
        "countryISO": "BH",
        "country": "Bahrain",
        "impressions": 770,
        "clicks": 17,
        "avgCPC": 0.001
    },
    "BI": {
        "countryISO": "BI",
        "country": "Burundi",
        "impressions": 85,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "BJ": {
        "countryISO": "BJ",
        "country": "Benin",
        "impressions": 211,
        "clicks": 3,
        "avgCPC": 0.002
    },
    "BL": {
        "countryISO": "BL",
        "country": "Saint Barthelemy",
        "impressions": 7,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "BM": {
        "countryISO": "BM",
        "country": "Bermuda",
        "impressions": 68,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "BN": {
        "countryISO": "BN",
        "country": "Brunei",
        "impressions": 2978,
        "clicks": 50,
        "avgCPC": 0.001
    },
    "BO": {
        "countryISO": "BO",
        "country": "Bolivia",
        "impressions": 4480,
        "clicks": 85,
        "avgCPC": 0.002
    },
    "BQ": {
        "countryISO": "BQ",
        "country": "Bonaire, Saint Eustatius and Saba ",
        "impressions": 11,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "BR": {
        "countryISO": "BR",
        "country": "Brazil",
        "impressions": 395916,
        "clicks": 3847,
        "avgCPC": 0.006
    },
    "BS": {
        "countryISO": "BS",
        "country": "Bahamas",
        "impressions": 422,
        "clicks": 9,
        "avgCPC": 0.001
    },
    "BT": {
        "countryISO": "BT",
        "country": "Bhutan",
        "impressions": 507,
        "clicks": 8,
        "avgCPC": 0.001
    },
    "BW": {
        "countryISO": "BW",
        "country": "Botswana",
        "impressions": 373,
        "clicks": 12,
        "avgCPC": 0.001
    },
    "BZ": {
        "countryISO": "BZ",
        "country": "Belize",
        "impressions": 592,
        "clicks": 14,
        "avgCPC": 0.001
    },
    "CA": {
        "countryISO": "CA",
        "country": "Canada",
        "impressions": 149140,
        "clicks": 3253,
        "avgCPC": 0.018
    },
    "CF": {
        "countryISO": "CF",
        "country": "Central African Republic",
        "impressions": 17,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "CH": {
        "countryISO": "CH",
        "country": "Switzerland",
        "impressions": 51174,
        "clicks": 885,
        "avgCPC": 0.009
    },
    "CI": {
        "countryISO": "CI",
        "country": "Ivory Coast",
        "impressions": 1078,
        "clicks": 22,
        "avgCPC": 0.002
    },
    "CK": {
        "countryISO": "CK",
        "country": "Cook Islands",
        "impressions": 45,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "CL": {
        "countryISO": "CL",
        "country": "Chile",
        "impressions": 149982,
        "clicks": 2081,
        "avgCPC": 0.006
    },
    "CM": {
        "countryISO": "CM",
        "country": "Cameroon",
        "impressions": 415,
        "clicks": 9,
        "avgCPC": 0.001
    },
    "CN": {
        "countryISO": "CN",
        "country": "China",
        "impressions": 2561,
        "clicks": 36,
        "avgCPC": 0.006
    },
    "CO": {
        "countryISO": "CO",
        "country": "Colombia",
        "impressions": 24809,
        "clicks": 516,
        "avgCPC": 0.006
    },
    "CR": {
        "countryISO": "CR",
        "country": "Costa Rica",
        "impressions": 3520,
        "clicks": 71,
        "avgCPC": 0.003
    },
    "CV": {
        "countryISO": "CV",
        "country": "Cabo Verde",
        "impressions": 66,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "CW": {
        "countryISO": "CW",
        "country": "Curacao",
        "impressions": 237,
        "clicks": 4,
        "avgCPC": 0.001
    },
    "CY": {
        "countryISO": "CY",
        "country": "Cyprus",
        "impressions": 561,
        "clicks": 11,
        "avgCPC": 0.001
    },
    "CZ": {
        "countryISO": "CZ",
        "country": "Czechia",
        "impressions": 26174,
        "clicks": 635,
        "avgCPC": 0.011
    },
    "DE": {
        "countryISO": "DE",
        "country": "Germany",
        "impressions": 190143,
        "clicks": 3743,
        "avgCPC": 0.012
    },
    "DJ": {
        "countryISO": "DJ",
        "country": "Djibouti",
        "impressions": 41,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "DK": {
        "countryISO": "DK",
        "country": "Denmark",
        "impressions": 19627,
        "clicks": 441,
        "avgCPC": 0.003
    },
    "DM": {
        "countryISO": "DM",
        "country": "Dominica",
        "impressions": 136,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "DO": {
        "countryISO": "DO",
        "country": "Dominican Republic",
        "impressions": 4143,
        "clicks": 87,
        "avgCPC": 0.002
    },
    "EC": {
        "countryISO": "EC",
        "country": "Ecuador",
        "impressions": 21600,
        "clicks": 436,
        "avgCPC": 0.003
    },
    "EE": {
        "countryISO": "EE",
        "country": "Estonia",
        "impressions": 322,
        "clicks": 7,
        "avgCPC": 0.014
    },
    "EG": {
        "countryISO": "EG",
        "country": "Egypt",
        "impressions": 10484,
        "clicks": 308,
        "avgCPC": 0.003
    },
    "ES": {
        "countryISO": "ES",
        "country": "Spain",
        "impressions": 378505,
        "clicks": 8502,
        "avgCPC": 0.008
    },
    "ET": {
        "countryISO": "ET",
        "country": "Ethiopia",
        "impressions": 418,
        "clicks": 9,
        "avgCPC": 0.001
    },
    "FI": {
        "countryISO": "FI",
        "country": "Finland",
        "impressions": 25417,
        "clicks": 434,
        "avgCPC": 0.002
    },
    "FJ": {
        "countryISO": "FJ",
        "country": "Fiji",
        "impressions": 546,
        "clicks": 8,
        "avgCPC": 0.001
    },
    "FK": {
        "countryISO": "FK",
        "country": "Falkland Islands",
        "impressions": 330,
        "clicks": 11,
        "avgCPC": 0.001
    },
    "FM": {
        "countryISO": "FM",
        "country": "Micronesia",
        "impressions": 45,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "FO": {
        "countryISO": "FO",
        "country": "Faroe Islands",
        "impressions": 24,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "FR": {
        "countryISO": "FR",
        "country": "France",
        "impressions": 472819,
        "clicks": 6405,
        "avgCPC": 0.013
    },
    "GA": {
        "countryISO": "GA",
        "country": "Gabon",
        "impressions": 310,
        "clicks": 4,
        "avgCPC": 0.001
    },
    "GB": {
        "countryISO": "GB",
        "country": "United Kingdom",
        "impressions": 116478,
        "clicks": 2247,
        "avgCPC": 0.022
    },
    "GD": {
        "countryISO": "GD",
        "country": "Grenada",
        "impressions": 303,
        "clicks": 6,
        "avgCPC": 0.001
    },
    "GE": {
        "countryISO": "GE",
        "country": "Georgia",
        "impressions": 910,
        "clicks": 25,
        "avgCPC": 0.005
    },
    "GF": {
        "countryISO": "GF",
        "country": "French Guiana",
        "impressions": 75,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "GG": {
        "countryISO": "GG",
        "country": "Guernsey",
        "impressions": 22,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "GH": {
        "countryISO": "GH",
        "country": "Ghana",
        "impressions": 7050,
        "clicks": 216,
        "avgCPC": 0.005
    },
    "GI": {
        "countryISO": "GI",
        "country": "Gibraltar",
        "impressions": 47,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "GL": {
        "countryISO": "GL",
        "country": "Greenland",
        "impressions": 70,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "GM": {
        "countryISO": "GM",
        "country": "Gambia",
        "impressions": 89,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "GN": {
        "countryISO": "GN",
        "country": "Guinea",
        "impressions": 90,
        "clicks": 2,
        "avgCPC": 0.006
    },
    "GP": {
        "countryISO": "GP",
        "country": "Guadeloupe",
        "impressions": 215,
        "clicks": 5,
        "avgCPC": 0.001
    },
    "GQ": {
        "countryISO": "GQ",
        "country": "Equatorial Guinea",
        "impressions": 60,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "GR": {
        "countryISO": "GR",
        "country": "Greece",
        "impressions": 88944,
        "clicks": 1831,
        "avgCPC": 0.008
    },
    "GT": {
        "countryISO": "GT",
        "country": "Guatemala",
        "impressions": 4359,
        "clicks": 84,
        "avgCPC": 0.002
    },
    "GU": {
        "countryISO": "GU",
        "country": "Guam",
        "impressions": 612,
        "clicks": 10,
        "avgCPC": 0.002
    },
    "GW": {
        "countryISO": "GW",
        "country": "Guinea-Bissau",
        "impressions": 23,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "GY": {
        "countryISO": "GY",
        "country": "Guyana",
        "impressions": 699,
        "clicks": 13,
        "avgCPC": 0.001
    },
    "HK": {
        "countryISO": "HK",
        "country": "Hong Kong",
        "impressions": 114772,
        "clicks": 2526,
        "avgCPC": 0.006
    },
    "HN": {
        "countryISO": "HN",
        "country": "Honduras",
        "impressions": 2666,
        "clicks": 52,
        "avgCPC": 0.003
    },
    "HR": {
        "countryISO": "HR",
        "country": "Croatia",
        "impressions": 36248,
        "clicks": 407,
        "avgCPC": 0.006
    },
    "HT": {
        "countryISO": "HT",
        "country": "Haiti",
        "impressions": 724,
        "clicks": 15,
        "avgCPC": 0.001
    },
    "HU": {
        "countryISO": "HU",
        "country": "Hungary",
        "impressions": 65074,
        "clicks": 1700,
        "avgCPC": 0.006
    },
    "ID": {
        "countryISO": "ID",
        "country": "Indonesia",
        "impressions": 232305,
        "clicks": 4163,
        "avgCPC": 0.003
    },
    "IE": {
        "countryISO": "IE",
        "country": "Ireland",
        "impressions": 38854,
        "clicks": 700,
        "avgCPC": 0.009
    },
    "IL": {
        "countryISO": "IL",
        "country": "Israel",
        "impressions": 6444,
        "clicks": 252,
        "avgCPC": 0.005
    },
    "IM": {
        "countryISO": "IM",
        "country": "Isle of Man",
        "impressions": 49,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "IN": {
        "countryISO": "IN",
        "country": "India",
        "impressions": 196385,
        "clicks": 4014,
        "avgCPC": 0.003
    },
    "IS": {
        "countryISO": "IS",
        "country": "Iceland",
        "impressions": 122,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "IT": {
        "countryISO": "IT",
        "country": "Italy",
        "impressions": 496335,
        "clicks": 7284,
        "avgCPC": 0.01
    },
    "JE": {
        "countryISO": "JE",
        "country": "Jersey",
        "impressions": 66,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "JM": {
        "countryISO": "JM",
        "country": "Jamaica",
        "impressions": 3130,
        "clicks": 65,
        "avgCPC": 0.001
    },
    "JP": {
        "countryISO": "JP",
        "country": "Japan",
        "impressions": 682726,
        "clicks": 8341,
        "avgCPC": 0.055
    },
    "KE": {
        "countryISO": "KE",
        "country": "Kenya",
        "impressions": 5422,
        "clicks": 107,
        "avgCPC": 0.003
    },
    "KG": {
        "countryISO": "KG",
        "country": "Kyrgyzstan",
        "impressions": 292,
        "clicks": 6,
        "avgCPC": 0.002
    },
    "KI": {
        "countryISO": "KI",
        "country": "Kiribati",
        "impressions": 4,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "KM": {
        "countryISO": "KM",
        "country": "Comoros",
        "impressions": 15,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "KN": {
        "countryISO": "KN",
        "country": "Saint Kitts and Nevis",
        "impressions": 138,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "KR": {
        "countryISO": "KR",
        "country": "South Korea",
        "impressions": 213154,
        "clicks": 4500,
        "avgCPC": 0.003
    },
    "KW": {
        "countryISO": "KW",
        "country": "Kuwait",
        "impressions": 22816,
        "clicks": 305,
        "avgCPC": 0.005
    },
    "KY": {
        "countryISO": "KY",
        "country": "Cayman Islands",
        "impressions": 84,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "KZ": {
        "countryISO": "KZ",
        "country": "Kazakhstan",
        "impressions": 2153,
        "clicks": 43,
        "avgCPC": 0.004
    },
    "LC": {
        "countryISO": "LC",
        "country": "Saint Lucia",
        "impressions": 245,
        "clicks": 5,
        "avgCPC": 0.001
    },
    "LI": {
        "countryISO": "LI",
        "country": "Liechtenstein",
        "impressions": 11,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "LK": {
        "countryISO": "LK",
        "country": "Sri Lanka",
        "impressions": 44674,
        "clicks": 382,
        "avgCPC": 0.003
    },
    "LS": {
        "countryISO": "LS",
        "country": "Lesotho",
        "impressions": 134,
        "clicks": 3,
        "avgCPC": 0.001
    },
    "LT": {
        "countryISO": "LT",
        "country": "Lithuania",
        "impressions": 22873,
        "clicks": 365,
        "avgCPC": 0.01
    },
    "LU": {
        "countryISO": "LU",
        "country": "Luxembourg",
        "impressions": 1710,
        "clicks": 39,
        "avgCPC": 0.002
    },
    "LV": {
        "countryISO": "LV",
        "country": "Latvia",
        "impressions": 257,
        "clicks": 7,
        "avgCPC": 0.01
    },
    "MA": {
        "countryISO": "MA",
        "country": "Morocco",
        "impressions": 3523,
        "clicks": 84,
        "avgCPC": 0.002
    },
    "MC": {
        "countryISO": "MC",
        "country": "Monaco",
        "impressions": 27,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "MD": {
        "countryISO": "MD",
        "country": "Moldova",
        "impressions": 787,
        "clicks": 25,
        "avgCPC": 0.004
    },
    "ME": {
        "countryISO": "ME",
        "country": "Montenegro",
        "impressions": 311,
        "clicks": 6,
        "avgCPC": 0.001
    },
    "MF": {
        "countryISO": "MF",
        "country": "Saint Martin",
        "impressions": 36,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "MG": {
        "countryISO": "MG",
        "country": "Madagascar",
        "impressions": 561,
        "clicks": 10,
        "avgCPC": 0.001
    },
    "MH": {
        "countryISO": "MH",
        "country": "Marshall Islands",
        "impressions": 33,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "MK": {
        "countryISO": "MK",
        "country": "North Macedonia",
        "impressions": 874,
        "clicks": 14,
        "avgCPC": 0.002
    },
    "ML": {
        "countryISO": "ML",
        "country": "Mali",
        "impressions": 204,
        "clicks": 4,
        "avgCPC": 0.002
    },
    "MN": {
        "countryISO": "MN",
        "country": "Mongolia",
        "impressions": 911,
        "clicks": 21,
        "avgCPC": 0.001
    },
    "MO": {
        "countryISO": "MO",
        "country": "Macao",
        "impressions": 298,
        "clicks": 5,
        "avgCPC": 0.001
    },
    "MP": {
        "countryISO": "MP",
        "country": "Northern Mariana Islands",
        "impressions": 71,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "MQ": {
        "countryISO": "MQ",
        "country": "Martinique",
        "impressions": 143,
        "clicks": 3,
        "avgCPC": 0.001
    },
    "MR": {
        "countryISO": "MR",
        "country": "Mauritania",
        "impressions": 411,
        "clicks": 14,
        "avgCPC": 0.001
    },
    "MT": {
        "countryISO": "MT",
        "country": "Malta",
        "impressions": 229,
        "clicks": 5,
        "avgCPC": 0.001
    },
    "MU": {
        "countryISO": "MU",
        "country": "Mauritius",
        "impressions": 1094,
        "clicks": 21,
        "avgCPC": 0.002
    },
    "MV": {
        "countryISO": "MV",
        "country": "Maldives",
        "impressions": 795,
        "clicks": 15,
        "avgCPC": 0.001
    },
    "MW": {
        "countryISO": "MW",
        "country": "Malawi",
        "impressions": 150,
        "clicks": 2,
        "avgCPC": 0.01
    },
    "MX": {
        "countryISO": "MX",
        "country": "Mexico",
        "impressions": 693265,
        "clicks": 9627,
        "avgCPC": 0.01
    },
    "MY": {
        "countryISO": "MY",
        "country": "Malaysia",
        "impressions": 761590,
        "clicks": 3939,
        "avgCPC": 0.01
    },
    "MZ": {
        "countryISO": "MZ",
        "country": "Mozambique",
        "impressions": 223,
        "clicks": 5,
        "avgCPC": 0.004
    },
    "NA": {
        "countryISO": "NA",
        "country": "Namibia",
        "impressions": 1325,
        "clicks": 60,
        "avgCPC": 0.001
    },
    "NC": {
        "countryISO": "NC",
        "country": "New Caledonia",
        "impressions": 262,
        "clicks": 4,
        "avgCPC": 0.001
    },
    "NE": {
        "countryISO": "NE",
        "country": "Niger",
        "impressions": 129,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "NI": {
        "countryISO": "NI",
        "country": "Nicaragua",
        "impressions": 817,
        "clicks": 15,
        "avgCPC": 0.002
    },
    "NL": {
        "countryISO": "NL",
        "country": "Netherlands",
        "impressions": 14745,
        "clicks": 252,
        "avgCPC": 0.01
    },
    "NO": {
        "countryISO": "NO",
        "country": "Norway",
        "impressions": 29358,
        "clicks": 497,
        "avgCPC": 0.01
    },
    "NR": {
        "countryISO": "NR",
        "country": "Nauru",
        "impressions": 31,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "NZ": {
        "countryISO": "NZ",
        "country": "New Zealand",
        "impressions": 51818,
        "clicks": 828,
        "avgCPC": 0.01
    },
    "OM": {
        "countryISO": "OM",
        "country": "Oman",
        "impressions": 1555,
        "clicks": 34,
        "avgCPC": 0.004
    },
    "PA": {
        "countryISO": "PA",
        "country": "Panama",
        "impressions": 3044,
        "clicks": 48,
        "avgCPC": 0.01
    },
    "PE": {
        "countryISO": "PE",
        "country": "Peru",
        "impressions": 361078,
        "clicks": 7277,
        "avgCPC": 0.004
    },
    "PF": {
        "countryISO": "PF",
        "country": "French Polynesia",
        "impressions": 234,
        "clicks": 4,
        "avgCPC": 0.001
    },
    "PG": {
        "countryISO": "PG",
        "country": "Papua New Guinea",
        "impressions": 241,
        "clicks": 5,
        "avgCPC": 0.001
    },
    "PH": {
        "countryISO": "PH",
        "country": "Philippines",
        "impressions": 116838,
        "clicks": 1468,
        "avgCPC": 0.003
    },
    "PK": {
        "countryISO": "PK",
        "country": "Pakistan",
        "impressions": 21832,
        "clicks": 515,
        "avgCPC": 0.002
    },
    "PL": {
        "countryISO": "PL",
        "country": "Poland",
        "impressions": 102549,
        "clicks": 1388,
        "avgCPC": 0.02
    },
    "PM": {
        "countryISO": "PM",
        "country": "Saint Pierre and Miquelon",
        "impressions": 302,
        "clicks": 10,
        "avgCPC": 0.001
    },
    "PR": {
        "countryISO": "PR",
        "country": "Puerto Rico",
        "impressions": 606,
        "clicks": 12,
        "avgCPC": 0.001
    },
    "PS": {
        "countryISO": "PS",
        "country": "Palestinian Territory",
        "impressions": 871,
        "clicks": 19,
        "avgCPC": 0.001
    },
    "PT": {
        "countryISO": "PT",
        "country": "Portugal",
        "impressions": 82935,
        "clicks": 1388,
        "avgCPC": 0.01
    },
    "PW": {
        "countryISO": "PW",
        "country": "Palau",
        "impressions": 54,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "PY": {
        "countryISO": "PY",
        "country": "Paraguay",
        "impressions": 1731,
        "clicks": 26,
        "avgCPC": 0.003
    },
    "RE": {
        "countryISO": "RE",
        "country": "Reunion",
        "impressions": 550,
        "clicks": 10,
        "avgCPC": 0.001
    },
    "RO": {
        "countryISO": "RO",
        "country": "Romania",
        "impressions": 92328,
        "clicks": 1692,
        "avgCPC": 0.01
    },
    "RS": {
        "countryISO": "RS",
        "country": "Serbia",
        "impressions": 3914,
        "clicks": 62,
        "avgCPC": 0.004
    },
    "RW": {
        "countryISO": "RW",
        "country": "Rwanda",
        "impressions": 173,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "SA": {
        "countryISO": "SA",
        "country": "Saudi Arabia",
        "impressions": 41848,
        "clicks": 662,
        "avgCPC": 0.01
    },
    "SB": {
        "countryISO": "SB",
        "country": "Solomon Islands",
        "impressions": 53,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "SC": {
        "countryISO": "SC",
        "country": "Seychelles",
        "impressions": 87,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "SD": {
        "countryISO": "SD",
        "country": "Sudan",
        "impressions": 1625,
        "clicks": 26,
        "avgCPC": 0.002
    },
    "SE": {
        "countryISO": "SE",
        "country": "Sweden",
        "impressions": 16634,
        "clicks": 281,
        "avgCPC": 0.01
    },
    "SG": {
        "countryISO": "SG",
        "country": "Singapore",
        "impressions": 40347,
        "clicks": 976,
        "avgCPC": 0.01
    },
    "SI": {
        "countryISO": "SI",
        "country": "Slovenia",
        "impressions": 19125,
        "clicks": 286,
        "avgCPC": 0.01
    },
    "SK": {
        "countryISO": "SK",
        "country": "Slovakia",
        "impressions": 10038,
        "clicks": 170,
        "avgCPC": 0.01
    },
    "SL": {
        "countryISO": "SL",
        "country": "Sierra Leone",
        "impressions": 84,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "SM": {
        "countryISO": "SM",
        "country": "San Marino",
        "impressions": 15,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "SN": {
        "countryISO": "SN",
        "country": "Senegal",
        "impressions": 1042,
        "clicks": 22,
        "avgCPC": 0.003
    },
    "SR": {
        "countryISO": "SR",
        "country": "Suriname",
        "impressions": 1108,
        "clicks": 21,
        "avgCPC": 0.001
    },
    "ST": {
        "countryISO": "ST",
        "country": "Sao Tome and Principe",
        "impressions": 7,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "SV": {
        "countryISO": "SV",
        "country": "El Salvador",
        "impressions": 2034,
        "clicks": 43,
        "avgCPC": 0.001
    },
    "SX": {
        "countryISO": "SX",
        "country": "Sint Maarten",
        "impressions": 106,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "SZ": {
        "countryISO": "SZ",
        "country": "Eswatini",
        "impressions": 78,
        "clicks": 2,
        "avgCPC": 0.001
    },
    "TC": {
        "countryISO": "TC",
        "country": "Turks and Caicos Islands",
        "impressions": 55,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "TD": {
        "countryISO": "TD",
        "country": "Chad",
        "impressions": 76,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "TG": {
        "countryISO": "TG",
        "country": "Togo",
        "impressions": 615,
        "clicks": 12,
        "avgCPC": 0.001
    },
    "TH": {
        "countryISO": "TH",
        "country": "Thailand",
        "impressions": 43955,
        "clicks": 1144,
        "avgCPC": 0.008
    },
    "TJ": {
        "countryISO": "TJ",
        "country": "Tajikistan",
        "impressions": 29,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "TK": {
        "countryISO": "TK",
        "country": "Tokelau",
        "impressions": 75,
        "clicks": 3,
        "avgCPC": 0.001
    },
    "TL": {
        "countryISO": "TL",
        "country": "Timor Leste",
        "impressions": 57,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "TM": {
        "countryISO": "TM",
        "country": "Turkmenistan",
        "impressions": 5,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "TN": {
        "countryISO": "TN",
        "country": "Tunisia",
        "impressions": 1040,
        "clicks": 23,
        "avgCPC": 0.003
    },
    "TO": {
        "countryISO": "TO",
        "country": "Tonga",
        "impressions": 31,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "TR": {
        "countryISO": "TR",
        "country": "Turkey",
        "impressions": 8191,
        "clicks": 193,
        "avgCPC": 0.01
    },
    "TT": {
        "countryISO": "TT",
        "country": "Trinidad and Tobago",
        "impressions": 2579,
        "clicks": 54,
        "avgCPC": 0.002
    },
    "TV": {
        "countryISO": "TV",
        "country": "Tuvalu",
        "impressions": 197,
        "clicks": 4,
        "avgCPC": 0.001
    },
    "TW": {
        "countryISO": "TW",
        "country": "Taiwan",
        "impressions": 136954,
        "clicks": 1796,
        "avgCPC": 0.01
    },
    "TZ": {
        "countryISO": "TZ",
        "country": "Tanzania",
        "impressions": 1081,
        "clicks": 15,
        "avgCPC": 0.003
    },
    "UA": {
        "countryISO": "UA",
        "country": "Ukraine",
        "impressions": 5073,
        "clicks": 137,
        "avgCPC": 0.01
    },
    "US": {
        "countryISO": "US",
        "country": "United States",
        "impressions": 404535,
        "clicks": 5479,
        "avgCPC": 0.03
    },
    "UY": {
        "countryISO": "UY",
        "country": "Uruguay",
        "impressions": 3606,
        "clicks": 56,
        "avgCPC": 0.003
    },
    "UZ": {
        "countryISO": "UZ",
        "country": "Uzbekistan",
        "impressions": 1772,
        "clicks": 34,
        "avgCPC": 0.01
    },
    "VC": {
        "countryISO": "VC",
        "country": "Saint Vincent and the Grenadines",
        "impressions": 217,
        "clicks": 7,
        "avgCPC": 0.001
    },
    "VE": {
        "countryISO": "VE",
        "country": "Venezuela",
        "impressions": 3352,
        "clicks": 63,
        "avgCPC": 0.002
    },
    "VG": {
        "countryISO": "VG",
        "country": "British Virgin Islands",
        "impressions": 39,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "VI": {
        "countryISO": "VI",
        "country": "U.S. Virgin Islands",
        "impressions": 161,
        "clicks": 4,
        "avgCPC": 0.001
    },
    "VN": {
        "countryISO": "VN",
        "country": "Vietnam",
        "impressions": 52828,
        "clicks": 1077,
        "avgCPC": 0.01
    },
    "WF": {
        "countryISO": "WF",
        "country": "Wallis and Futuna",
        "impressions": 4,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "WS": {
        "countryISO": "WS",
        "country": "Samoa",
        "impressions": 46,
        "clicks": 0,
        "avgCPC": 0.001
    },
    "XK": {
        "countryISO": "XK",
        "country": "Kosovo",
        "impressions": 327,
        "clicks": 9,
        "avgCPC": 0.001
    },
    "YT": {
        "countryISO": "YT",
        "country": "Mayotte",
        "impressions": 72,
        "clicks": 1,
        "avgCPC": 0.001
    },
    "ZA": {
        "countryISO": "ZA",
        "country": "South Africa",
        "impressions": 74479,
        "clicks": 1164,
        "avgCPC": 0.01
    },
    "ZM": {
        "countryISO": "ZM",
        "country": "Zambia",
        "impressions": 1921,
        "clicks": 63,
        "avgCPC": 0.003
    }
}
};
